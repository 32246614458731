<template>
	<div>
		<HeaderPhone style="position: fixed;"></HeaderPhone>
		<div style="height: 50px;"></div>
		<div class="banner" >
			<div style="height: 10vh;"></div>
			<div class="left-text flex_items">
				<div>专注数智化产业链接与</div>
				<div style="height: 0.1vh;"></div>
				<div style="color: #BB9468;">科创孵化平台</div>
			</div>
		</div>
		<div style="padding: 0 20px;margin-bottom: 35px;">
			<div style="color: #4B4B4B;">
				<div class="module_title">公司介绍</div>
				<div class="module_subtitle">聚焦于数字信息、数字安全、数字文化、数字能源四大产业</div>
				<div>
					<div style="text-align: center;">
						<el-image :src="require('../static/images/index/Mask-group.png')" class="imgAdaptive"></el-image>
					</div>
					<div style="font-size: 16px;margin-top: 19px;line-height: 26px;text-align: justify;text-justify: auto;">
						<div>
							广东网宇科技股份有限公司（以下简称“网宇科创”）是一家专注数字化产业链接与科技创新孵化的平台公司，聚焦于数字信息、数字安全、数字文化、数字能源四大产业。成立近十年以来，致力于产业数字化的创新与深度融合，助力不同行业数字科技创新与产业升级，实现价值共赢。
						</div>
						<ul style="padding-left: 15px;margin-top: 10px;">
							<li>数字信息，包括数字应用系统与平台、网络信息化软硬件等领域。</li>
							<li>数字安全，包括以运维安全、数据治理、工业数字化等安全领域。</li>
							<li>数字文化，包括数字影视、数字传媒、数字设计、数字教育、数字图文等领域。</li>
							<li>数字能源，包括数字化绿色能源、数字化储能等相应领域。</li>
						</ul>
					</div>
					
					<div style="margin-top: 25px;">
						<el-image :src="require('../static/imagesPhone/index/Group 127.jpg')" class="imgAdaptive" ></el-image>
					</div>
				</div>
			</div>
		</div>
		<div style="margin-bottom: 35px;padding: 0 20px;">
			<div style="color: #4B4B4B;">
				<div class="module_title">投资领域</div>
				<div class="module_subtitle">创新之道：探索人工智能、大数据、网络安全、数字文化与新能源的投资未来</div>
			</div>
			<div style="text-align: center;">
				<el-image :src="item" class="imgAdaptive" v-for="(item,index) in imageList" :key="index" style="margin-bottom: 15px;"></el-image>
			</div>
		</div>
		<div style="padding: 0 20px;margin-bottom: 50px;">
			<div style="color: #4B4B4B;">
				<div class="module_title">资讯中心</div>
				<div class="module_subtitle">数字化革新，投资未来。探索数字化产业的最新资讯与前沿趋势</div>
				<div style="text-align: center;">
					<el-image :src="require('../static/imagesPhone/index/Mask group.jpg')" class="imgAdaptive"></el-image>
				</div>
				<div @click="$router.push('/article?id=1')">
					<div class="ellipsis" style="font-size: 18px;color: #053A8A;font-weight: 600;margin-top: 10px;">证监会：加大长期资金对股权科创基金的投资力度</div>
					<div style="margin: 10px 0 3px 0;">推动相关行业主管部门完善法律法规，加大以社保基金、保险资金、养老基金等为代表的长...</div>
					<div style="color: #B3B3B3;">2023-10-26</div>
				</div>
				<div style="margin: 20px 0;" @click="$router.push('/article?id=2')">
					<div class="ellipsis" style="margin-bottom: 3px;">证监会：支持头部证券公司通过并购重组等方式做优做强 打造一流的投资银行</div>
					<div style="color: #B3B3B3;">2023-10-26</div>
				</div>
				<div @click="$router.push('/article?id=3')">
					<div style="margin-bottom: 3px;">广东设立新型储能产业天使投资基金</div>
					<div style="color: #B3B3B3;">2023-10-26</div>
				</div>
			</div>
		</div>
		<div style="color: #ffffff;">
			<div class="flex_items" style="background-color: #264472;padding: 20px;">
				<div style="margin-right: 10px;">
					<el-image :src="require('@/static/imagesPhone/index/Group 14.jpg')" style="width: 72px;height: 63px;"></el-image>
				</div>
				<div>
					<p style="font-size: 18px;">联系地址</p>
					<p style="margin: 10px 0 3px 0;">广东省 东莞市 南城区 黄金路1号天安数码城H1栋16-17楼</p>
					<p>福建省 厦门市 集美区 软件园三期F10栋</p>
				</div>
			</div>
			<div class="flex_items" style="background-color: #D6B98E;padding: 20px;">
				<div style="margin-right: 10px;">
					<el-image :src="require('@/static/imagesPhone/index/Group 11.jpg')" style="width: 72px;height: 63px;"></el-image>
				</div>
				<div>
					<p style="font-size: 18px;margin-bottom: 10px;">联系方式</p>
					<p>Bp@wangyu.com</p>
				</div>
			</div>
		</div>
		<div style="background-color: #F5F5F5; padding: 30px 20px;">
			<div class="module_title" style="margin-top: 0px;">合作伙伴</div>
			<div class="module_subtitle" style="">携手合作，共创数字化时代的投资新篇章。与业界领先合作伙伴一同助力数字化产业的创新发展</div>
			<div>
				<swiper :options="swiperOptions" ref="mySwiper">
				  <swiper-slide v-for="(item, index) in logoList" :key="index">
					  <div class="slide-content">
						  
					  </div>
					 <img :src="item" :alt="`Slide ${index + 1}`" class="imgAdaptive" />
				  </swiper-slide>
				</swiper>
			</div>
		</div>
		<FooterPhone></FooterPhone>
	</div>
</template>

<script>
import HeaderPhone from "@/components/headerPhone";
import FooterPhone from "@/components/footerPhone";
import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
export default {
	data() {
		return {
			// IntroList: [
			// 	{ src: require('../static/imagesPhone/index/Group 124.jpg'),title: '使命',con: '赋能科技企业，成就非凡梦想'},
			// 	{ src: require('../static/imagesPhone/index/Group 125.jpg'),title: '愿景',con: '成为全球领先的数字化产业投资孵化平台'},
			// 	{ src: require('../static/imagesPhone/index/Group 126.jpg'),title: '价值观',con: '创新开拓，赋能共创'},
			// ],
			logoList: [
				require('../static/images/index/logo/Group 65.jpg'),
				require('../static/images/index/logo/Group 66.png'),
				require('../static/images/index/logo/Group 67.png'),
				require('../static/images/index/logo/Group 68.png'),
				require('../static/images/index/logo/Group 69.png'),
				require('../static/images/index/logo/Group 70.jpg'),
				require('../static/images/index/logo/Group 71.png'),
				require('../static/images/index/logo/Group 72.png'),
				require('../static/images/index/logo/Group 73.png'),
				require('../static/images/index/logo/Group 74.png'),
			],
			swiperOptions: {
				slidesPerView: 2, 
				spaceBetween: 5, // 设置图之间的间距
				loop: true,
				autoplay: {
				  delay: 800,
				},
			},
			imageList: [
				require('../static/imagesPhone/index/Group 87.jpg'),
				require('../static/imagesPhone/index/Group 85.jpg'),
				require('../static/imagesPhone/index/Group 86.jpg'),
				require('../static/imagesPhone/index/Group 84.jpg'),
				require('../static/imagesPhone/index/Group 83.jpg'),
			],
		}
	},
	components: {
		HeaderPhone,
		FooterPhone,
		Swiper,
		SwiperSlide,
	},
	methods: {
		
	},
}
</script>

<style scoped>
.banner {
	height: 38vh;
	background: url("../static/imagesPhone/index/image 18.jpg") no-repeat;
	background-size: cover;
	background-position: center center;
}
.banner .left-text {
	font-weight: 600;
	color: #4B4B4B;
	font-size: 18px;
	flex-direction: column;
}
.module_title {
	font-size: 18px;
	font-weight: 600;
	color: #4B4B4B;
	text-align: center;
	margin: 30px 0 8px 0;
}
.module_subtitle {
	margin-bottom: 18px;
	font-size: 14px;
	color: #D6B98E;
	text-align: center;
}
.imgAdaptive {
	max-width: 100%;
	height: auto;
}
.ellipsis {
	white-space: nowrap;       /* 禁止文本换行 */
	overflow: hidden;          /* 隐藏溢出内容 */
	text-overflow: ellipsis;   /* 显示省略号 */
}

.slide-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

</style>